<template>
  <div class="login">
    <div class="login-content">
      <img class="right" src="@/assets/login-r.jpg" alt="" srcset="" />
      <img class="logo" src="@/assets/logo-2.png" alt="" srcset="" />
      <p class="login-title">云巴蜀课程博览馆</p>
      <div class="input-warp">
        <img
          class="icon"
          slot="prefix"
          src="@/assets/login-user.png"
          alt=""
          srcset=""
        />
        <input
          class="input"
          v-model="tname"
          placeholder="手机号"
          autocomplete="false"
        />
      </div>
      <div class="input-warp pwd">
        <img
          class="icon"
          slot="prefix"
          src="@/assets/login-pwd.png"
          alt=""
          srcset=""
        />
        <input
          class="input"
          v-model="tpwd"
          placeholder="密码"
          type="password"
          autocomplete="false"
        />
      </div>
      <a-button
        type="primary"
        :loading="loading"
        class="login-btn"
        @click="tologinAction"
        >登录</a-button
      >

      <div class="toregister">
        <span @click="toRegisterAction">注册</span>
        <!-- <span>忘记密码</span> -->
      </div>
    </div>
  </div>
</template>

<script>
import bgimg from "@/assets/login-bg2.png";
import { timeFix } from "@/utils/util";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      bgimg: bgimg,
      tname: "",
      tpwd: "",
      loading: false,
    };
  },
  methods: {
    ...mapActions(["Login"]),
    tologinAction() {
      //   console.log('login', Login)
      if (this.tname.length == 0) {
        this.$message.warning("请输入账号");
        return;
      }
      if (this.tpwd.length == 0) {
        this.$message.warning("请输入密码");
        return;
      }
      this.loading = true;
      this.Login({ tname: this.tname, tpwd: this.tpwd })
        .then((res) => {
          console.log("loginres", res);
          this.loading = false;
          this.loginSuccess(res);
        })
        .catch((err) => {
          if (err) {
            this.loading = false;
          }
        });
      //   this.$router.push({name: "home"})
    },
    toRegisterAction() {
      this.$router.push({ name: "register" });
    },
    loginSuccess(res) {
      if (this.$route.query.redirect) {
        this.$router.push(this.$route.query.redirect);
      } else {
        this.$router.push({ path: "/" });
      }
      setTimeout(() => {
        this.$notification.success({
          message: "欢迎",
          description: `${timeFix()}，欢迎回来`,
        });
      }, 1000);
    },
  },
};
</script>

<style lang="less">
.login {
    height: 100%;
    position: relative;
    .login-content {
        width: 1150Px;
        height: 531Px;
        margin:  auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-radius: 15Px;
        background: white;
        .right {
            width: 512Px;
            height: 530Px;
            position: absolute;
            right: 0;
            bottom: 0;
        }
        .logo {
            width: 177Px;
            height: 52Px;
            position: absolute;
            left: 52Px;
            top: 38Px;
        }
        .login-title {
            font-size: 41Px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            line-height: 77px;
            color: #2A2A31;
            position: absolute;
            left: 84Px;
            top: 140Px;
        }
        .input-warp {
            position: absolute;
            left: 84pX;
            top: 237Px;
            width: 343Px;
            height: 44Px;
            border-radius: 6Px;
            background: #EFF7FF;
            .icon {
                position: absolute;
                top: 10Px;
                left: 10Px;
                width: 20Px;
                height: 24Px;
            }
            .input {
                border: none;
                outline: none;
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                background: #EFF7FF;
                left: 40Px;
                height: 100%;
            }
            .input:focus {
                border: none;
            }
            
        }
        .pwd {
            top: 312Px;
            .icon {
                width: 20Px;
                height: 24Px;
                object-fit: contain;
            }
        }
        .login-btn {
            width: 343Px;
            height: 50Px;
            background: linear-gradient(90deg, #52C4F9 0%, #5C69E3 100%);
            opacity: 1;
            border-radius: 50Px;
            text-align: center;
            line-height: 50Px;
            font-size: 16Px;
            color: #FFFFFF;
            position: absolute;
            left: 84Px;
            bottom: 81Px;
            cursor: pointer;
            border: none;
        }
        .toregister {
            position: absolute;
            left: 84Px;
            bottom: 44Px;
            display: flex;
            justify-content: space-between;
            width: 343Px;
            padding: 0 20Px;
            color: #8F8C8C;
            span {
                cursor: pointer;
            }
        }
    }
}

</style>