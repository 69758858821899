<template>
  <div class="registre" :style="{ backgroundImage: 'url(' + bgimg + ')' }">
    <div
      class="register-content"
      :style="{ backgroundImage: 'url(' + cbgimg + ')' }"
    >
      <p class="big-title">云巴蜀课程博览馆</p>
      <ul class="form">
        <li class="item">
          <img class="icon" src="@/assets/username.png" alt="" />
          <input
            class="inp"
            v-model="name"
            type="text"
            placeholder="姓名"
            autocomplete="false"
          />
        </li>
        <li class="item">
          <img class="icon" src="@/assets/tel.png" alt="" />
          <input
            class="inp"
            v-model="phone"
            type="text"
            placeholder="电话"
            autocomplete="false"
          />
        </li>
        <li class="item">
          <img class="icon" src="@/assets/rpwd.png" alt="" />
          <input
            class="inp"
            v-model="pwd"
            type="password"
            placeholder="密码"
            autocomplete="false"
          />
        </li>
        <li class="item">
          <img class="icon" src="@/assets/school.png" alt="" />
          <input
            class="inp"
            v-model="school"
            type="text"
            placeholder="学校"
            autocomplete="false"
          />
        </li>
        <li class="item">
          <img class="icon" src="@/assets/subject.png" alt="" />
          <input
            class="inp"
            v-model="teach"
            type="text"
            placeholder="任教学科"
            autocomplete="false"
          />
        </li>
        <li class="item">
          <img class="icon" src="@/assets/address.png" alt="" />
          <a-cascader
            :options="areas"
            :field-names="{
              label: 'AreaName',
              value: 'AreaName',
              children: 'Children',
            }"
            @change="onChange"
          >
            <input
              class="inp"
              v-model="area"
              type="text"
              placeholder="学校所在地"
              autocomplete="false"
            />
          </a-cascader>
        </li>
      </ul>
      <a-button
        type="primary"
        :loading="loading"
        class="register-btn"
        @click="registerAction"
        >注册</a-button
      >
      <div class="to-login">
        <span style="color: #8e8e93">已有账号，</span>
        <span @click="tologinAction">马上登录</span>
      </div>
    </div>
  </div>
</template>

<script>
import bgimg from "@/assets/register-bg.jpg";
import cbgimg from "@/assets/register-content.png";
import { Cascader } from "ant-design-vue";
import { register } from "@/api/user";
import json from "@/utils/area.json";
export default {
  components: {
    "a-cascader": Cascader,
  },
  data() {
    return {
      loading: false,
      bgimg: bgimg,
      cbgimg: cbgimg,
      name: "",
      phone: "",
      pwd: "",
      school: "",
      teach: "",
      province: "",
      city: "",
      area: "",
      areas: json.province,
    };
  },
  mounted() {
    this.$message.config({
      top: "100px",
    });
  },
  methods: {
    registerAction() {
      if (this.name.length == 0) {
        this.$message.warning("请输入您的姓名");
        return;
      }
      if (this.phone.length == 0) {
        this.$message.warning("请输入电话号码");
        return;
      }
      if (this.pwd.length == 0) {
        this.$message.warning("请输入密码");
        return;
      }

      if (this.school.length == 0) {
        this.$message.warning("请输入所在学校");
        return;
      }
      if (this.teach.length == 0) {
        this.$message.warning("请输入任教科目");
        return;
      }
      if (this.area.length == 0) {
        this.$message.warning("请选择地址");
        return;
      }
      let params = {
        name: this.name,
        phone: this.phone,
        pwd: this.pwd,
        teach: this.teach,
        school: this.school,
        area: this.area,
      };
      this.loading = true;
      register({
        model: JSON.stringify(params),
      })
        .then((res) => {
          console.log(res);
          this.loading = false;
          if (res.code == 200) {
            this.$message.success('注册成功，快去登录吧！',()=>{
              this.$router.push({name:'login'})
            })
          }
        })
        .catch((err) => {
          if (err) {
            this.loading = false;
          }
        });
    },
    tologinAction() {
      this.$router.push({ name: "login" });
    },
    onChange(value, selectedOptions) {
      console.log(selectedOptions);
      this.area = selectedOptions.map((o) => o.AreaName).join(" - ");
    },
  },
};
</script>

<style lang="less">
.registre {
    height: 100%;
    position: relative;
    .register-content {
        width: 563Px;
        height: 530Px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background-size: 563Px 530Px;
        .big-title {
          text-align: center;
          font-weight: bold;
          font-size: 20Px;
          padding-top: 100Px;
        }
        .form {
            position: absolute;
            left: 75Px;
            top: 150Px;
            padding: 0;
            .item {
                width: 410Px;
                height: 36Px;
                background: white;
                margin-bottom: 12Px;
                position: relative;
                border-radius: 6Px;
                .icon {
                width: 21Px;
                height: 21Px;
                position: absolute;
                top: 7Px;
                left: 10Px;
                }
                .inp {
                    border: none;
                    outline: none;
                    position: absolute;
                    top: 0;
                    width: 300PX;
                    bottom: 0;
                    left: 41Px;
                    display: block;
                }
                .inp:focus {
                    border: none;
                }
            }
        }
        .register-btn {
            width: 410Px;
            height: 45Px;
            background: linear-gradient(90deg, #52C4F9 0%, #5C69E3 100%);
            border-radius: 6Px;
            line-height: 45Px;
            text-align: center;
            font-size: 15Px;
            color: #FFFFFF;
            position: absolute;
            left: 75Px;
            bottom: 40Px;
            border: none;
            cursor: pointer;
        }
        .to-login {
            font-size: 12Px;
            line-height: 9Px;
            color: #6E76FF;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 20Px;
            text-align: center;
            cursor: pointer;
        }
    }
}
</style>